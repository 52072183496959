/*--------------------------------------------------------------
15. Funfact
----------------------------------------------------------------*/
.st-funfact.st-style1 {
  text-align: center;
  background-color: $white;
  border-radius: 7px;
  padding: 30px 20px;
  border: 1px solid $black4;
  transition: all 0.3s ease;
  &:hover {
    border-color: transparent;
    box-shadow: 0px 1px 30px 0px rgba(112, 112, 112, 0.15);
  }

  .st-funfact-icon {
    font-size: 36px;
    margin-bottom: 22px;
    height: 70px;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .st-funfact-number {
    font-size: 36px;
    margin-bottom: 3px;
  }

  .st-funfact-title {
    font-size: 20px;
    line-height: 1.6em;
    font-weight: 600;
    margin-bottom: -8px;
  }
}
