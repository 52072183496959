/*--------------------------------------------------------------
30. Select
----------------------------------------------------------------*/
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  flex: 1;
}

.select2-container .select2-selection--single {
  height: 42px;
}

.select2-container--default .select2-selection--single {
  border-color: $black4;
  border-radius: 7px;
  &:focus {
    outline: none;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 30px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: $black1;
  line-height: 1.6em;
  font-size: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: $black3 transparent transparent transparent;
}

.select2-search--dropdown .select2-search__field {
  border-radius: 7px;
  height: 36px;
  padding: 5px 15px;
}

.select2-dropdown {
  border-color: $black4;
  border-radius: 7px;
}

.select2-search--dropdown {
  padding: 8px;
  padding-bottom: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
  width: 30px;
  background: transparent;
}

.st_pos_invoice_topbar .select2-container--default .select2-selection--single {
  margin-right: -1px;
  outline: none;
}

.select2-results__options {
  padding: 8px;
}

.select2-results__option {
  font-size: 15px;
  line-height: 1.2em;
  padding: 10px 12px;
  border-radius: 7px;
  margin-bottom: 1px;
  color: $black2;
}

.select2-container--default .select2-results__option[aria-selected='true'],
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: rgba($blue, 0.1);
  color: $blue;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border-color: $black3;
  font-size: 15px;
  line-height: 1.6em;
}

.st_large_select {
  flex: 1;
}

.st_large_select .select2-container,
.st_small_select .select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 5;
}

.st_pos_search .select2-container--default .select2-selection--single {
  border-radius: 5px 0 0 5px;
}

.st_small_select {
  flex: none;
  width: 120px;
}

.st_small_select .select2-container--default .select2-selection--single {
  border-radius: 5px 0 0 5px;
}

.st_small_select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #34c759;
  font-weight: 500;
}
