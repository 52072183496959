/*--------------------------------------------------------------
7. Slider
----------------------------------------------------------------*/
.st-slider.st-style1 {
  position: relative;
}
.slick-hidden {
  display: none !important;
}
.swipe-arrow.st-hidden,
.pagination.st-hidden {
  display: none;
}

.pagination.st-style1,
.pagination.st-style2 {
  ul {
    padding: 0;
    margin: 0;
    display: flex !important;
    list-style: none;
  }

  li {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: $black2;
    margin-right: 8px;
    cursor: pointer;
    transition: all 0.4s ease;

    &.slick-active {
      background-color: $blue;
    }
  }

  button {
    display: none;
  }
}

.pagination.st-style2 {
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translateX(-50%);
}

.pagination.st-style1.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  padding-left: 15px;
}

.st-slider.st-style2,
.st-slider.st-style3 {
  position: relative;

  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;

    > div {
      display: flex;

      > .slick-slide-in {
        width: 100%;
      }
    }
  }

  .slick-list {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.st-slider.st-style2 {
  .slick-arrow {
    box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: all 0.4s ease;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 30px;
    }

    &.slick-arrow-left {
      left: 0px;

      &:before {
        right: -20px;
      }
    }

    &.slick-arrow-right {
      right: 0px;

      &:before {
        left: -20px;
      }
    }

    &:hover {
      color: $black1;
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      visibility: visible;

      &.slick-arrow-left {
        left: -70px;
      }

      &.slick-arrow-right {
        right: -70px;
      }
    }
  }
}

.swipe-arrow.st-style3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  .slick-arrow {
    height: 42px;
    width: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;

    &:hover {
      background-color: $black2;
      color: #fff;
    }
  }
}

@media screen and (max-width: 991px) {
  .st-slider .pagination.st-hidden {
    display: block;
    margin-top: 20px;
  }

  .st-slider .pagination.st-style1 ul,
  .st-slider .pagination.st-style2 ul {
    justify-content: center;
  }

  .swipe-arrow.st-style1 {
    display: none;
  }

  .st-hidden.st-hidden-md,
  .swipe-arrow.st-style2 {
    display: none !important;
  }
}
